.unitButton {
    height: 45px;
    width: 45px;
    border-radius: 80%;
    background-color: #100e1d;
    border: none;
    color: #e7e7eb;
    font-size: 18px;
    margin-right: 15px;
}
.unitButton:last-child {
    margin-right: 0;
}

.active {
    background-color: white;
    color: black;
}
