.summaryPanel {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: #1E213A ;
    width: 100%;
    min-height: 100vh;
    color: #88869D;
    padding: 80px 40px;
    background-image: url("./Cloud-background_blue.png");
    background-repeat: no-repeat;
    background-attachment: local;
    background-size: contain;

}



.summaryPanel .temperature{
    font-size: 144px;
    color: #E7E7EB;
}
small{
    font-size: 48px;
    color: #88869D;
}
.weatherLabel {
    font-size: 36px;
}
.summaryPanel img {
    width: 150px;
}

