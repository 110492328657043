@import-normalize;

body,
html {
    padding: 0;
    margin: 0;
}
body {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 18px;
    background-color: #100e1d;
}
* {
    box-sizing: border-box;
}
