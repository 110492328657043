.card {
    display: block;
    box-sizing: border-box;
    background-color: #1e213a;
    margin: 20px;
    padding: 10px 0 20px 0;
    color: white;
}

.heading {
    font-weight: 500;
    line-height: 19px;
    font-style: normal;
    font-size: 16px;
}
