.Link {
    background-color: #6e707a;
    font-style: normal;
    font-family: "Raleway", "sans-serif";
    font-size: 18px;
    line-height: 45px;
    color: #e7e7eb;
    box-sizing: border-box;
    height: 45px;
    padding: 0 20px;
    text-decoration: none;
}

.Link > i {
    margin-right: 0.7em;
}
