.unit {
    font-size: 36px;
    line-height: 43px;
    font-style: normal;
    font-weight: 400;
}

.measurements {
    font-size: 64px;
    line-height: 76px;
    font-style: normal;
    font-weight: 700;
}

.summaryPanel {
    background-color: #61dafb;
    position: relative;
}

.unitButtons {
    position: absolute;
    top: 20px;
    right: 20px;
}

.locationButton {
    position: absolute;
    top: 20px;
    left: 20px;
}
.innerContainer{
    padding: 40px 35px;
}
.innerContainer > :first-child{
    margin: 0 -10px;
}

@media only screen and (min-width: 992px) {
    .container {
        display: flex;
        justify-content: space-between;
        max-height: 100vh;
    }
    .container > :first-child {
        flex-grow: 1;
    }
    .container > :last-child {
        flex-grow: 2;
    }
    .innerContainer{
        max-width: 90%;
        margin: auto;
    }



}
