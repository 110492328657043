.locationDisplay {
    background-color: #1e213a;
    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("./Cloud-background_blue.png");
    background-repeat: repeat;
    background-attachment: local;
    background-size: auto;
    padding: 20px;
}
.locationDisplay i {
    color: #e7e7eb;
    font-size: 24px;
    font-weight: 700;
    align-self: flex-end;
}
.locationDisplay input {
    margin: 10px 10px;
    padding: 0 25px;
    width: 250px;
    height: 45px;
    background-color: transparent;
    box-sizing: border-box;
    border: 2px solid #e7e7eb;
    color: #e7e7eb;
}

.locationListItem {
    list-style: none;
}
.locationResult {
    display: block;
    text-decoration: none;
    color: #e7e7eb;
    font-family: "Raleway", "sans-serif";
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding: 20px;
    border: 1px solid transparent;
}

.locationResult:hover {
    border: 1px solid #e7e7eb;
}

.locationList {
    padding-left: 0;
}
.title {
    color: #e7e7eb;
    line-height: 1.3em;
    margin: 30px 0;
    font-size: 45px;
    font-weight: 400;
}

.text {
    color: #e7e7eb;
    margin: 20px;
    font-size: 25px;
}
.locationDisplay img {
    width: 50%;
    max-width: 400px;
    height: auto;
    margin: 30px;
}
@media only screen and (min-width: 992px) {
    .locationDisplay img {
        width: 40%;
        max-width: 400px;
        height: auto;
        margin: 50px 0;
    }
    .locationDisplay input{
        width: 350px;
        height: 50px;
        margin-top: 30px;
        border: 3px solid #e7e7eb;
    }
}
