.weatherCard {
    width: 135px;
    padding: 20px 20px 20px 20px;
    margin: 10px;
    border: 1px solid black;
    background-color: #1e213a;
    color: #e7e7eb;
    font-size: 16px;
}
.minTemperature {
    color: #a09fb1;
}

.date {
    margin-bottom: 20px;
}
.temperatures {
    display: flex;
    justify-content: space-between;
}

.weatherLabel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 65px;
    margin-bottom: 10px;
}

.weatherLabel img {
    width: 54px;
}
